$small: 480px;

.paper {
  width: 80%;
  margin-top: -200px;
  display: flex;

  @media screen and (max-width: $small) {
    width: auto;
  }
}
