.root {
	:global {
		.MuiBackdrop-root {
			background-color: rgba(235, 110, 110, 0);
		}
		.MuiCircularProgress-colorPrimary {
			color: white;
		}
	}
	z-index: 9999999!important;
}
.scrollPaper, .paperScrollPaper {
	border-radius: 20px !important;
	background-color: transparent !important;
}
